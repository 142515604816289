
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import DefaultLayout from '@/components/DefaultLayout.vue';
import { QuoteService } from '@/services/quote-service';
import ItemService from '@/services/item-service';
@Component({
  components: { DefaultLayout }
})
export default class extends Vue {
  $refs!: {
    defaultActions: HTMLFormElement;
  };
  /* Properties */
  /* Store Actions */
  /* Watchers */
  /* Data */
  tabs: any = [
    {
      name: '',
      index: 1,
      numberAcross: 0,
      NumberOfRolls: 8,
      MaxLabelsPerRoll: 0,
      level: 1,
      itemId: '',
      bwItemId: '',
      bwlevel: 2,
      height: 0,
      width: 0,
      show: false,
      src: '',
      partNumber: ''
    }
  ];
  selectedTab: any = {
    name: 'Preferred',
    numberAcross: 0,
    NumberOfRolls: 8,
    MaxLabelsPerRoll: 0,
    level: 1,
    itemId: '',
    bwItemId: '',
    bwlevel: 2,
    height: 0,
    width: 0,
    show: false,
    src: '',
    partNumber: ''
  };
  active: any = null;
  total: number = 0;
  quantity: number | string = 0;
  previousQuantity: number = 0;
  shouldUseBlackAndWhite: boolean = false;
  bwHasChanged: boolean = false;
  shouldLoad: boolean = false;
  isLoadingStaticItems: boolean = true;
  isLoadingDieDimensions: boolean = false;
  isUserNameValid: boolean = false;
  isCustomerNameValid: boolean = false;
  shouldTransition: boolean = false;
  dataPointsValid: number = 0;
  errors: any[] = [];
  shouldShowSlider: boolean = false;
  handApplyQuantity: number = 1;
  dialog: boolean = false;
  templateDialog: boolean = false;
  valid: boolean = true;
  /* Computed */
  get isBlackAndWhite() {
    return this.shouldUseBlackAndWhite;
  }
  get imageHeight() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
        return '100px';
      case 'sm':
        return '200px';
      case 'md':
        return '201px';
      case 'lg':
        return '202px';
      case 'xl':
        return '203px';
      default:
        return '202px';
    }
  }
  /* Utility Functions */
  next(number: number) {
    this.active = this.tabs[number].name;
    this.getTransparencyQuote(this.tabs[number]);
    for (var i = 0; i < this.tabs.lengh; i++) {
      this.tabs[i].show = false;
    }
    this.tabs[number].show = true;
    this.selectedTab = this.tabs[number];
  }
  calculateTotal(tab: any) {
    //To limit multiple JSON requests
    if (this.active == tab.name) {
      if (
        this.quantity !== this.previousQuantity ||
        tab.NumberOfRolls == 0 ||
        this.bwHasChanged
      ) {
        this.previousQuantity = this.quantity as number;
        this.bwHasChanged = false;
        this.getTransparencyQuote(tab);
      }
    }
  }
  calculateRollDistribution(tab: any) {
    if (this.quantity == undefined || this.quantity == 0) {
      return 'Receive 0 rolls @@ approximately 0 per roll';
    }
    return `Receive ${tab.NumberOfRolls} rolls @@ approximately ${(this
      .quantity as number) / tab.NumberOfRolls} per roll`;
  }
  activeColor(tab: any) {
    if (this.active == tab.name) {
      if (this.selectedTab.name !== tab.name) {
        this.selectedTab = tab;
      }
      return 'green';
    }
    return 'black';
  }
  async getTransparencyQuote(tab: any) {
    if (this.quantity !== undefined && this.quantity > 7) {
      this.shouldLoad = true;
      var level = tab.level;
      var staticItemId = tab.itemId;
      if (this.isBlackAndWhite) {
        level = tab.bwlevel;
        staticItemId = tab.bwItemId;
      }
      const { data } = await QuoteService.GetTransparencyQuote(
        this.quantity as number,
        tab.numberAcross,
        level,
        staticItemId
      );
      if (parseInt(this.quantity as string) === data.quantityInput) {
        this.total = data.CustomerOrderTotalFull;
      }
      this.shouldLoad = false;
    }
  }
  async getStaticItems(shouldLoadBwItems: boolean, shouldLoadAllItems: boolean) {
    this.isLoadingStaticItems = true;
    const { data } = await ItemService.GetStaticItems();
    this.tabs.length = 0;
    var index = 1;
    let returnValue = data.sort((a: { IsType4: any }) => (a.IsType4 ? -1 : 1));
    for (var item of data) {
      var newTab = {
        name: item.Description,
        numberAcross: 0,
        NumberOfRolls: 8,
        MaxLabelsPerRoll: 0,
        level: 1,
        itemId: item.PublicId,
        bwItemId: item.BwItemPublicId,
        bwlevel: 2,
        height: item.Height,
        width: item.Width,
        show: false,
        src: item.Src,
        index: index,
        partNumber: item.PartNumber
      };
      this.tabs.push(newTab);
      index++;
    }
    this.selectedTab = this.tabs[0];
    this.active = this.selectedTab.name;
    this.isLoadingStaticItems = false;
  }
  calc(theform: number) {
    var num = theform;
    var with2Decimals = num.toString().match(/^-?\d+(?:\.\d{0,2})?/)![0];
    return with2Decimals;
  }
  isActive(name: string) {
    return this.active === name;
  }
  openEmailClient() {
    var sendTo = 'transparency-support@amazon.com';
    var subject = 'Request to Authorize TSP (Just One Label, LLC)';
    var body = `To whom it may concern,%0D%0A%0D%0A
                    This is an official request to authorize the TSP (Just One Label, LLC) to programmatically request codes on our behalf.%0A%0ABrand Name: MY COMPANY NAME%0ABrand ID: MY BRAND ID`;
    window.open(`mailto:${sendTo}?subject=${subject}&body=${body}`);
  }
  downloadExcel() {
    window.open('/Content/templates/Transparency Add Items (Excel).xlsx');
  }
  downloadCSV() {
    window.open('/Content/templates/Transparency Add Items (CSV).csv');
  }
  shouldShowQuantity() {
    if (this.quantity == 0) {
      this.quantity = '';
    }
    if (this.quantity == undefined || this.quantity == '') {
      this.total = 0;
      this.tabs.forEach(function(tab: any) {
        tab.NumberOfRolls = 8;
        tab.MaxLabelsPerRoll = 0;
      });
    }
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    this.selectedTab = this.tabs[0];
    this.active = this.selectedTab.name;
  }
  /* Created */
  created() {
    this.getStaticItems(false, false);
  }
  /* Emmited Functions */
}
